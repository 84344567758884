import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import FileUploader from 'components/common/file-uploader';
import history from 'helpers/history';
import api from 'helpers/api';

class ReportInterference extends Component {
  constructor(props) {
    super(props);
    this.state = { notes: '', fileProgress: 100, loading: false };

    this.handleReport = this.handleReport.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleFileProgress = this.handleFileProgress.bind(this);
    this.onFile = this.onFile.bind(this);
  }

  componentDidMount() {
    document.title = 'Report Interference | Flamingo Admin';
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  handleReport() {
    const vehicleId = this.props.match.params.vehicleId;
    const { notes, photoUrl, fileProgress, loading } = this.state;

    if (loading) {
      return;
    }

    if (fileProgress < 100) {
      return window.alert('Please wait, your photo is still uploading.');
    }

    if (notes.length <= 0) {
      return window.alert('Please provide a description/note.');
    }

    this.setState({ loading: true });
    const data = { vehicleId, photoUrl, notes };
    api.post(`/report/interference`, data)
      .then((res) => alert(`Report created successfully.`))
      .then(() => history.go(-2))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  handleNotesChange(e) {
    const notes = e.target.value;
    this.setState({ notes });
  }

  onFile(file) {
    this.setState({ photoUrl: file.url });
  }

  handleFileProgress(fileProgress) {
    this.setState({ fileProgress });
  }

  render() {
    const { loading, notes } = this.state;
    return (
      <div className="fm-report-interference">
        <NavigationBar title="Report Interference" loading={loading} showBack={true} />
        <div className="fm-report-interference-content">
          <p className="fm-report-interference-info">Use this form to report interference with scooters and bikes. This includes graffiti, tampering and unauthorised movement of scooters/bikes. Notes and images are required.</p>
          <input type="text" autoFocus={true} className="fm-input" value={notes} onChange={this.handleNotesChange} placeholder="Notes" />
          <FileUploader accept="images" type="report" resize={1800} onFinish={this.onFile} onProgress={this.handleFileProgress} />
          <button className="fm-report-interference-button" onClick={this.handleReport}>{ loading ? 'Loading...' : 'Submit Report' }</button>
        </div>
      </div>
    );
  }
}

export default ReportInterference;
