import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/common/navigation-bar';
import LoadingLine from 'components/common/loading-line';
import Map, { Marker, Polygon, Circle } from 'components/common/map';
import moment from 'moment';
import api from 'helpers/api';
import colors from 'helpers/colors';
import user from 'helpers/user';

const filterTypes = ['NORIDING', 'NOHIRE', 'NOPARKING', 'LOWSPEED', 'LOWSPEEDNOPARKING', 'PREFERREDPARKING', 'PUBLICTRANSPORT'];
const parkingAreaIcon = { url: 'https://storage.googleapis.com/flamingo-static/images/admin/parking-area.png?v2', size: new window.google.maps.Size(22, 22), scaledSize: new window.google.maps.Size(22, 22), anchor: new window.google.maps.Point(11, 11) };

class ZoneMap extends Component {
  constructor() {
    super();
    this.state = { zones: [], types: [...filterTypes], parkingAreas: [], regions: [], tiers: [], date: moment(), loading: true, permissions: {} };

    this.loadZones = this.loadZones.bind(this);
    this.loadFilters = this.loadFilters.bind(this);
    this.toggleType = this.toggleType.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.select = this.select.bind(this);
    this.selectParkingArea = this.selectParkingArea.bind(this);
    this.closeSelected = this.closeSelected.bind(this);

    this.renderOption = this.renderOption.bind(this);
  }

  componentDidMount() {
    document.title = 'Zones | Flamingo Admin';
    this.loadFilters();
    this.loadRegions();
    this.loadParkingAreas();
    user.getCurrentUser().then((user) => this.setState({ permissions: user.admin }));
  }

  /* ------ Map ------ */

  loadZones() {
    const { types, date } = this.state;
    let endpoint = `/zone/area?active=true`;

    if (types && types.length > 0 && types.length !== filterTypes.length) {
      endpoint += types.map((type) => `&type[]=${type}`).join('');
    }

    if (date) {
      endpoint += `&date=${encodeURIComponent(date.format())}`;
    }

    return api.get(endpoint)
      .then((res) => this.setState({ zones: res.data.data, loading: false }))
      .catch(console.log);
  }

  loadRegions() {
    return api.get('/region/service-area')
      .then((res) => this.setState({ regions: res.data.data, tiers: res.data.data.flatMap(region => region.tiers || []) }))
      .catch(console.log);
  }

  loadParkingAreas() {
    return api.get('/parking/areas')
      .then((res) => this.setState({ parkingAreas: res.data.data }))
      .catch(console.log);
  }

  /* ------ Filters ------ */

  loadFilters() {
    const filtersString = localStorage.getItem('zoneFilters');
    if (filtersString) {
      const types = JSON.parse(filtersString);
      this.setState({ types }, this.loadZones);
    } else {
      this.loadZones();
    }
  }

  toggleType(type) {
    const types = this.state.types;

    const typeIndex = types.indexOf(type);
    if (typeIndex > -1) {
      types.splice(typeIndex, 1);
      if (types.length === 0) {
        return;
      }
    } else {
      types.push(type);
    }

    this.setState({ types, loading: true }, this.loadZones);
    localStorage.setItem('zoneFilters', JSON.stringify(types));
  }

  onDateChange(e) {
    this.setState({ date: moment(e.target.value), loading: true }, this.loadZones);
  }

  select(zone) {
    this.setState({ selectedZone: zone, selectedParkingArea: false });
  }

  selectParkingArea(parkingArea) {
    this.setState({ selectedZone: false, selectedParkingArea: parkingArea });
  }

  closeSelected() {
    this.setState({ selectedZone: false, selectedParkingArea: false });
  }

  /* ------ Renders ------ */

  renderOption(type, i) {
    const style = { color: '#222222', background: '#F8F7F9' };

    if (this.state.types.includes(type)) {
      style.background = colors.zone[type];
      style.color = '#FFF';
    }

    return (
      <span
        className="fm-zone-map-filters-type"
        key={i}
        style={style}
        onClick={() => this.toggleType(type)}
      >
        { type.toUpperCase() }
      </span>
    );
  }

  activeDays(zone) {
    let days = [];
    if (zone.monday) {
      days.push('Monday');
    }
    if (zone.tuesday) {
      days.push('Tuesday');
    }
    if (zone.wednesday) {
      days.push('Wednesday');
    }
    if (zone.thursday) {
      days.push('Thursday');
    }
    if (zone.friday) {
      days.push('Friday');
    }
    if (zone.saturday) {
      days.push('Saturday');
    }
    if (zone.sunday) {
      days.push('Sunday');
    }
    return days.length === 7 ? 'Everyday' : days.join(', ');
  }

  render() {
    const { zones, regions, tiers, parkingAreas, date, selectedZone, selectedParkingArea, permissions, loading } = this.state;
    const newZoneAction = { to: '/zone/new', icon: 'plus-circle' };

    return (
      <div className="fm-zone-map">
        <NavigationBar title="Zones" rightActions={[newZoneAction]} />
        <div className="fm-zone-map-main">
          <div className="fm-zone-map-holder">
            <Map google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
              { regions.map((region, i) => <Polygon key={i} paths={ region.serviceArea.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeColor={colors.zone.serviceArea} strokeWeight={2} fillOpacity={0} clickable={false} />) }
              { tiers.map((tier, i) => <Polygon key={i} paths={ tier.area.map((p) => ({ lat: p[0], lng: p[1] })) } strokeColor={'rgba(52, 152, 219, 0.5)'} strokeWeight={2} fillOpacity={0} clickable={false} />) }
              { zones.map((zone, i) => <Polygon key={i} paths={ zone.polygon.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeColor={colors.zone[zone.type]} strokeWeight={2} fillColor={colors.zone[zone.type]} fillOpacity={0.2} onClick={() => this.select(zone)} />) }
              { selectedParkingArea && <Circle position={{ lat: selectedParkingArea.latitude, lng: selectedParkingArea.longitude }} radius={selectedParkingArea.radius} fillOpacity={0.25} fillColor={'#4A90E2'} strokeWeight={0} /> }
              { parkingAreas.map((parkingArea, i) => <Marker key={i} position={{ lat: parkingArea.latitude, lng: parkingArea.longitude }} title={parkingArea.name} icon={parkingAreaIcon} onClick={() => this.selectParkingArea(parkingArea)} />) }
            </Map>
          </div>
          {
            selectedZone &&
            <div className="fm-zone-map-info">
              <div className="fm-zone-map-info-content">
                <p className="fm-zone-map-info-name">{ selectedZone.name }</p>
                <p className="fm-zone-map-info-value">{ selectedZone.message }</p>
                { selectedZone.speedLimit && <p className="fm-zone-map-info-value">{ selectedZone.speedLimit } km/h speed limit.</p> }
                <p className="fm-zone-map-info-value">{ selectedZone.startTime.substring(0, 5) } to { selectedZone.endTime.substring(0, 5) }</p>
                <p className="fm-zone-map-info-value">{ this.activeDays(selectedZone) }</p>
                <p className="fm-zone-map-info-value">ID: { selectedZone.id }</p>
              </div>
              <div className="fm-zone-map-info-actions">
                {
                  permissions.showDashboard &&
                  <Link className="fm-zone-map-info-action" to={{ pathname: `/zone/${selectedZone.id}/edit`, state: { zone: selectedZone } }}>Edit</Link>
                }
                <button className="fm-zone-map-info-action" onClick={this.closeSelected}>Close</button>
              </div>
            </div>
          }
          {
            selectedParkingArea &&
            <div className="fm-zone-map-info">
              <div className="fm-zone-map-info-content">
                <p className="fm-zone-map-info-name">{ selectedParkingArea.name }</p>
                <p className="fm-zone-map-info-value">{ selectedParkingArea.description }</p>
                <img className="fm-zone-map-info-photo" alt={ selectedParkingArea.name } src={ selectedParkingArea.photoUrl } />
                <p className="fm-zone-map-info-value">ID: { selectedParkingArea.id }</p>
              </div>
              <div className="fm-zone-map-info-actions">
                <button className="fm-zone-map-info-action" onClick={this.closeSelected}>Close</button>
              </div>
            </div>
          }
        </div>
        <div className="fm-zone-map-filters">
          <div className="fm-zone-map-filters-types">
            { filterTypes.map(this.renderOption) }
          </div>
          <div className="fm-zone-map-filters-options">
            <input type="datetime-local" value={date.format('YYYY-MM-DDTHH:mm')} onChange={this.onDateChange} className="fm-zone-map-filters-input fm-input" disabled={loading} />
          </div>
        </div>
        <LoadingLine hide={!loading} />
      </div>
    );
  }
}

export default ZoneMap;
