import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

class DocumentTemplate extends Component {
  constructor(props) {
    super(props);
    const preloadedTemplate = props.location.state ? props.location.state.template : false;
    this.state = {
      template: preloadedTemplate || {},
      versions: [],
      generateDate: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      loading: true,
      error: false,
    };
    this.loadTemplate = this.loadTemplate.bind(this);
    this.loadVersions = this.loadVersions.bind(this);
    this.onGenerate = this.onGenerate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderVersion = this.renderVersion.bind(this);
  }

  componentDidMount() {
    if (this.state.template) {
      document.title = `${this.state.template.title} | Flamingo Admin`;
    } else {
      document.title = 'Document Template | Flamingo Admin';
    }
    this.loadTemplate();
    this.loadVersions();
  }

  loadTemplate() {
    return api.get(`/document/template/${this.props.match.params.templateId}`)
      .then((res) => this.setState({ template: { ...this.state.template, ...res.data.data }, loading: false }))
      .catch(this.handleError);
  }

  loadVersions() {
    return api.get(`/document/version?documentTemplateId=${this.props.match.params.templateId}`)
      .then((res) => this.setState({ versions: res.data.data }))
      .catch(this.handleError);
  }

  onGenerate() {
    const { generateDate } = this.state;
    this.setState({ loading: true });
    return api.post(`/document/template/${this.props.match.params.templateId}/generate`, { date: generateDate })
      .then((res) => history.push(`/document/${this.props.match.params.templateId}/version/${res.data.data.id}`))
      .catch(this.handleError);
  }

  onChange(e) {
    const generateDate = e.target.value;
    this.setState({ generateDate });
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  renderVersion(version, i) {
    return (
      <Link className="fm-document-template-item" key={i} to={{ pathname: `/document/${this.props.match.params.templateId}/version/${version.id}`, state: { version } }}>
        <p className="fm-document-template-item-subtitle">{ version.subtitle }</p>
        <div className="fm-document-templates-item-details">
          <p className="fm-document-templates-item-status">{ version.status }</p>
          <p className="fm-document-templates-item-created">{ moment(version.createdAt).format('D/M/YY') }</p>
        </div>
      </Link>
    );
  }

  render() {
    const { loading, error, template, versions, generateDate } = this.state;
    const title = template.title || 'Document Template';

    return (
      <div className="fm-document-template">
        <NavigationBar title={title} loading={loading} showBack={true} />
        <div className="fm-document-template-content">
          <div className="fm-document-template-side">
            <div className="fm-document-template-side-generate">
              <p className="fm-document-template-side-generate-title">Generate Version</p>
              <div className="fm-document-template-side-generate-form">
                <input type="date" className="fm-input" onChange={this.onChange} value={generateDate} />
                <button className="fm-document-template-side-generate-button" onClick={this.onGenerate}>Generate</button>
              </div>
            </div>
            <div className="fm-document-template-side-versions">
              <p className="fm-document-template-side-versions-title">Versions</p>
              <div className="fm-document-template-side-versions-items">
                { versions.map(this.renderVersion) }
              </div>
            </div>
          </div>
          <div className="fm-document-template-details">
            { template.markdown }
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default DocumentTemplate;
