import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import Toast from 'components/common/toast';
import LoadingLine from 'components/common/loading-line';
import colors from 'helpers/colors';
import api from 'helpers/api';
import user from 'helpers/user';
import history from 'helpers/history';
import supportCache from 'helpers/support-cache';

const vehicleType = { SCOOTER: 'Scooter', BIKE: 'Bike' };
const categories = [
  { value: 'account', label: 'Account', icon: 'user', description: 'Account support, ie updating a name or payment issues.' },
  { value: 'complaint', label: 'Parking/Riding Complaint', icon: 'times-circle', description: 'Parking and riding complaints, including relocation requests.' },
  { value: 'enquiry', label: 'Enquiry', icon: 'question', description: 'General enquiries and partnerships.' },
  { value: 'incident', label: 'Incident', icon: 'exclamation-triangle', description: 'Reports of incidents and accidents.' },
  { value: 'trip', label: 'Trip', icon: 'route', description: 'Support for trips, including app and vehicle issues.' },
  { value: 'vehicle', label: 'Vehicle', icon: 'dot-circle', description: 'Support for vehicles when not in a trip.' },
];

const taskColor = {
  available: '#eb4d4b',
  reserved: '#d5d5d5',
  expired: '#999',
  collected: '#20bf6b',
  completed: '#20bf6b',
  cancelled: '#999',
};

class SupportTicket extends Component {
  constructor(props) {
    super(props);

    const preloadedTicket = props.location.state ? props.location.state.ticket : false;
    const preloadedItems = preloadedTicket ? (preloadedTicket.items || []) : false;

    this.state = {
      ticket: preloadedTicket || {},
      items: preloadedItems || [],
      user: {},
      statistics: {},
      regions: [],
      assignees: [],
      templates: [],
      composerContent: '',
      loading: true,
      posting: false,
    };

    this.loadTicket = this.loadTicket.bind(this);
    this.loadAssignees = this.loadAssignees.bind(this);
    this.loadRegions = this.loadRegions.bind(this);
    this.loadTemplates = this.loadTemplates.bind(this);
    this.loadNextTicket = this.loadNextTicket.bind(this);
    this.loadStatistics = this.loadStatistics.bind(this);
    this.handleTicket = this.handleTicket.bind(this);
    this.handleTicketPatch = this.handleTicketPatch.bind(this);
    this.handleNextTicket = this.handleNextTicket.bind(this);
    this.handleStatistics = this.handleStatistics.bind(this);
    this.handleError = this.handleError.bind(this);

    this.isUrl = this.isUrl.bind(this);
    this.handleAdjustVoucher = this.handleAdjustVoucher.bind(this);
    this.adjustVoucher = this.adjustVoucher.bind(this);
    this.attachTask = this.attachTask.bind(this);

    this.onContentChange = this.onContentChange.bind(this);
    this.handleTemplate = this.handleTemplate.bind(this);
    this.onSend = this.onSend.bind(this);
    this.onResolve = this.onResolve.bind(this);
    this.onReopen = this.onReopen.bind(this);
    this.onResolveSend = this.onResolveSend.bind(this);
    this.onNote = this.onNote.bind(this);
    this.onChatGPT = this.onChatGPT.bind(this);

    this.showCategory = this.showCategory.bind(this);
    this.showAssign = this.showAssign.bind(this);
    this.showRegion = this.showRegion.bind(this);
    this.hidePop = this.hidePop.bind(this);
    this.popBackgroundClick = this.popBackgroundClick.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
    this.handleUnassign = this.handleUnassign.bind(this);
    this.handleRegion = this.handleRegion.bind(this);
    this.handleCategory = this.handleCategory.bind(this);
    this.keyListener = this.keyListener.bind(this);

    this.renderItem = this.renderItem.bind(this);

    this.popBackground = React.createRef();
  }

  componentDidMount() {
    document.title = `Support Ticket | Flamingo Admin`;
    this.loadTicket();
    this.loadTemplates();
    this.keyListener();
    user.getCurrentUser().then((user) => this.setState({ user }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.ticketId !== this.props.match.params.ticketId) {
      const preloadedTicket = this.props.location.state ? this.props.location.state.ticket : false;
      const preloadedItems = preloadedTicket ? (preloadedTicket.items || []) : false;
      const newState = {
        ticket: preloadedTicket || {},
        items: preloadedItems || [],
        statistics: {},
      };
      this.setState(newState, this.loadTicket);
    }
  }

  /* NETWORKING */

  loadTicket() {
    this.setState({ loading: true, autoLoadNextTicket: false });
    return api.get(`/support/ticket/${this.props.match.params.ticketId}`)
      .then(this.handleTicket)
      .catch(this.handleError);
  }

  handleTicket(res) {
    const ticket = res.data.data;
    const items = ticket.items || [];
    document.title = `${ticket.subject} | Flamingo Admin`;
    this.setState({ ticket, items, loading: false, posting: false }, this.loadStatistics);
  }

  handleTicketPatch(res) {
    const newTicket = res.data.data;
    const ticket = this.state.ticket;
    let { items, posting, composerContent, popupCallback, autoLoadNextTicket, didUnassign, didReopen } = this.state;
    let newItems = false;
    const keys = Object.keys(newTicket);
    for (var i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (key === 'items' && newTicket.items.length > items.length) {
        items = newTicket.items;
        newItems = true;
      } else {
        ticket[key] = newTicket[key];
      }
    }
    if (didUnassign) {
      ticket.assignedUser = undefined;
    }
    if (didReopen) {
      ticket.resolvedAt = undefined;
    }
    if (posting && newItems) {
      composerContent = '';
    }
    if (popupCallback) {
      if (popupCallback === 'resolveSend') {
        this.onResolveSend();
      } else {
        this.onResolve();
      }
    } else {
      if (autoLoadNextTicket) {
        this.setState({ ticket, items, composerContent }, this.loadNextTicket);
      } else {
        this.setState({ ticket, items, composerContent, posting: false, loading: false, didUnassign: false, didReopen: false });
      }
    }
  }

  loadAssignees() {
    return supportCache.getAgents()
      .then((assignees) => this.setState({ assignees }))
      .catch(this.handleError);
  }

  loadRegions() {
    return supportCache.getRegions()
      .then((regions) => this.setState({ regions }))
      .catch(this.handleError);
  }

  loadTemplates() {
    return supportCache.getTemplates()
      .then((templates) => this.setState({ templates }))
      .catch(this.handleError);
  }

  loadNextTicket() {
    return api.get(`/support/ticket?status=unassigned&limit=1&afterSupportTicketId=${this.props.match.params.ticketId}`)
      .then(this.handleNextTicket)
      .catch(this.handleError);
  }

  handleNextTicket(res) {
    const tickets = res.data.data;
    if (tickets[0]) {
      return this.setState({ ticket: tickets[0] }, () => {
        history.replace(`/support/ticket/${tickets[0].id}`);
      });
    } else {
      history.push('/support/unassigned')
    }
  }

  loadStatistics() {
    return api.get(`/support/ticket/${this.props.match.params.ticketId}/statistics`)
      .then(this.handleStatistics)
      .catch(this.handleError);
  }

  handleStatistics(res) {
    this.setState({ statistics: res.data.data });
  }

  handleError(e) {
    let error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    if (error === 'validation' && window.access(() => e.response.data.validation[0])) {
      error = `Invalid ${e.response.data.validation[0]}`;
    }
    this.setState({ error, loading: false, posting: false });
  }

  /* ACTIONS */

  handleAdjustVoucher() {
    if (!this.state.ticket.associatedUser) {
      return window.alert('Unable to find account.');
    }
    const adjustmentString = window.prompt(`Enter the amount to adjust the user's voucher credit by. Use a number followed by the letter C for credit, M for minutes or U for unlocks. For example to add $5 credit type 5C. To subtract 10 minutes type -10M.`, '');
    if (adjustmentString != null) {
      let adjustmentType = adjustmentString.substr(adjustmentString.length - 1).toLowerCase();
      let adjustmentAmount = parseFloat(adjustmentString.slice(0, -1));

      if (!adjustmentAmount) {
        return alert('Invalid adjustment amount.')
      }

      if (!['c', 'm', 'u'].includes(adjustmentType)) {
        return alert('Invalid adjustment type. Please use the letter C for credit, M for minutes or U for unlocks.')
      }

      let adjustmentSummary = '';
      switch (adjustmentType) {
        case 'm':
          adjustmentSummary = adjustmentAmount > 0 ? `add ${adjustmentAmount} minutes to` : `remove ${adjustmentAmount * -1} minutes from`;
          adjustmentType = 'MINUTES';
          break;
        case 'c':
          adjustmentSummary = adjustmentAmount > 0 ? `add $${adjustmentAmount} credit to` : `remove $${adjustmentAmount * -1} credit from`;
          adjustmentAmount = parseInt((adjustmentAmount * 100).toFixed());
          adjustmentType = 'CREDIT';
          break;
        case 'u':
          adjustmentSummary = adjustmentAmount > 0 ? `add ${adjustmentAmount} unlocks to` : `remove ${adjustmentAmount * -1} unlocks from`;
          adjustmentType = 'UNLOCKS';
          break;
        default:
          break;
      }

      let adjustmentConfirm = `Are you sure you want to ${adjustmentSummary} this user?`;
      if (window.confirm(adjustmentConfirm)) {
        return this.adjustVoucher(adjustmentType, adjustmentAmount);
      }
    }
  }

  adjustVoucher(type, value) {
    this.setState({ loading: true });
    return api.post(`/user/${this.state.ticket.associatedUser.id}/adjust-vouchers`, { type, value })
      .then(() => this.setState({ loading: false, error: 'Voucher added!' }))
      .catch(this.handleError);
  }

  attachTask() {
    const taskUrlString = window.prompt(`Enter the full URL to the task below. Enter "null" to remove the task.`, '');
    if (taskUrlString != null) {
      const urlRegex = /https:\/\/admin\.flamingoscooters\.com\/task\/\d+/g;
      const foundTaskUrl = taskUrlString.match(urlRegex);
      if (foundTaskUrl) {
        const taskId = parseInt(taskUrlString.match( /\d+$/g)[0]);
        this.setState({ loading: true });
        return api.post(`/support/ticket/${this.props.match.params.ticketId}/task`, { taskId })
          .then(this.handleTicketPatch)
          .catch(this.handleError);
      } else if (taskUrlString === 'null') {
        this.setState({ loading: true });
        return api.post(`/support/ticket/${this.props.match.params.ticketId}/task`, { taskId: null })
          .then(this.handleTicketPatch)
          .then(() => { const ticket = this.state.ticket; ticket.associatedTask = undefined; this.setState({ ticket }); })
          .catch(this.handleError);
      } else {
        window.alert('Invalid task URL. Try again and enter the full URL like "https://admin.flamingoscooters.com/task/12345".')
      }
    }
  }

  /* COMPOSER */

  onContentChange(e) {
    this.setState({ composerContent: e.target.value });
  }

  handleTemplate(e) {
    const { templates, ticket, user } = this.state;
    const template = templates[e.target.value];
    const recipient = ticket.name.split(' ')[0];
    const agent = user.firstName || 'Flamingo';
    const cost = ticket.associatedTrip ? (ticket.associatedTrip.actualCost / 100).toFixed(2) : '0.00';
    const vehicle = ticket.associatedVehicle ? ticket.associatedVehicle.type.toLowerCase() : 'scooter';
    const startDate = moment(ticket.associatedTrip ? ticket.associatedTrip.startTime : undefined);
    const startTime = moment(ticket.associatedTrip ? ticket.associatedTrip.startTime : undefined).format('h:mma');
    const endTime = moment(ticket.associatedTrip ? ticket.associatedTrip.endTime : undefined).format('h:mma');
    const daysAgo = moment().endOf('day').diff(startDate, 'days');
    const date = daysAgo === 0 ? 'today' : (daysAgo === 1 ? 'yesterday' : `on the ${startDate.format('Do of MMMM')}`);
    const composerContent = template.content.replace('{{recipient}}', recipient).replace('{{agent}}', agent).replace('{{cost}}', cost).replace('{{date}}', date).replace('{{startTime}}', startTime).replace('{{endTime}}', endTime).replace(/\{\{vehicle\}\}/g, vehicle);
    this.setState({ composerContent, templateName: template.name })
  }

  onNote() {
    const { composerContent, ticket } = this.state;
    this.setState({ posting: true });
    api.post(`/support/ticket/${ ticket.id }/item`, { content: composerContent, type: 'note' })
      .then(this.handleTicketPatch)
      .catch(this.handleError);
  }

  onChatGPT() {
    const { ticket, composerContent } = this.state;
    const content = composerContent.length > 0 ? composerContent : null;
    const confirmMessage = !!content ? 'Are you sure you want ChatGPT to revise your response? Alternatively, leave the text area blank for ChatGPT to compose a response.' : 'Are you sure you want ChatGPT to compose a response? Alternatively, write your draft in the text area for ChatGPT to revise your response.';
    if (window.confirm(confirmMessage)) {
      this.setState({ posting: true });
      api.post(`/support/ticket/${ ticket.id }/suggest`, { content })
        .then((res) => this.setState({ posting: false, composerContent: res.data.data.suggestion.content }))
        .catch(this.handleError);
    }
  }

  onResolve(e) {
    const { ticket } = this.state;
    const autoLoadNextTicket = e ? e.shiftKey : this.state.autoLoadNextTicket;
    if (!ticket.category) {
      return this.setState({ popupCallback: 'resolve', posting: true, autoLoadNextTicket }, this.showCategory);
    }
    if (ticket.category === 'complaint' && !ticket.region) {
      return this.setState({ popupCallback: 'resolve', posting: true, autoLoadNextTicket }, this.showRegion);
    }
    this.setState({ posting: true, popupCallback: false, autoLoadNextTicket });
    api.post(`/support/ticket/${ ticket.id }/resolve`)
      .then(this.handleTicketPatch)
      .catch(this.handleError);
  }

  onSend() {
    const { composerContent, ticket } = this.state;
    this.setState({ posting: true, autoLoadNextTicket: false });
    api.post(`/support/ticket/${ ticket.id }/item`, { content: composerContent, type: 'text' })
      .then(this.handleTicketPatch)
      .catch(this.handleError);
  }

  onResolveSend(e) {
    const { composerContent, ticket } = this.state;
    const autoLoadNextTicket = e ? e.shiftKey : this.state.autoLoadNextTicket;
    if (!ticket.category) {
      return this.setState({ popupCallback: 'resolveSend', posting: true, autoLoadNextTicket }, this.showCategory);
    }
    if (ticket.category === 'complaint' && !ticket.region) {
      return this.setState({ popupCallback: 'resolveSend', posting: true, autoLoadNextTicket }, this.showRegion);
    }
    this.setState({ posting: true, popupCallback: false, autoLoadNextTicket });
    api.post(`/support/ticket/${ ticket.id }/item`, { content: composerContent, type: 'text', resolve: true })
      .then(this.handleTicketPatch)
      .catch(this.handleError);
  }

  onReopen() {
    const { ticket } = this.state;
    this.setState({ posting: true, popupCallback: false, didReopen: true });
    api.post(`/support/ticket/${ ticket.id }/open`)
      .then(this.handleTicketPatch)
      .catch(this.handleError);
  }

  /* POP */

  showCategory() {
    this.setState({ categoryVisible: true });
  }

  showAssign() {
    if (this.state.assignees.length === 0) {
      this.loadAssignees();
    }
    this.setState({ assignVisible: true });
  }

  showRegion() {
    if (this.state.regions.length === 0) {
      this.loadRegions();
    }
    this.setState({ regionVisible: true });
  }

  hidePop() {
    this.setState({ categoryVisible: false, assignVisible: false, regionVisible: false, posting: false, popupCallback: false });
  }

  popBackgroundClick(e) {
    if (e.target === this.popBackground.current) {
      this.hidePop();
    }
  }

  handleCategory(category) {
    const { ticket } = this.state;
    ticket.category = category;
    this.setState({ loading: true, categoryVisible: false, ticket });
    return api.post(`/support/ticket/${this.props.match.params.ticketId}/category`, { category })
      .then(this.handleTicketPatch)
      .catch(this.handleError);
  }

  handleRegion(regionId) {
    this.setState({ loading: true, regionVisible: false });
    return api.post(`/support/ticket/${this.props.match.params.ticketId}/region`, { regionId })
      .then(this.handleTicketPatch)
      .catch(this.handleError);
  }

  handleAssign(userId) {
    this.setState({ loading: true, assignVisible: false });
    return api.post(`/support/ticket/${this.props.match.params.ticketId}/assign`, { userId })
      .then(this.handleTicketPatch)
      .catch(this.handleError);
  }

  handleUnassign() {
    this.setState({ loading: true, assignVisible: false, didUnassign: true });
    return api.post(`/support/ticket/${this.props.match.params.ticketId}/unassign`)
      .then(this.handleTicketPatch)
      .catch(this.handleError);
  }

  keyListener() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      var isEscape = false;
      if ('key' in evt) {
        isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
      } else {
        isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
        this.hidePop();
      }
    };
  }

  /* UTILITY */

  isUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  /* RENDERS */

  renderItem(item, key) {
    const source = item.type === 'NOTE' ? 'fm-support-ticket-item-note' : (item.source === 'EXTERNAL' ? 'fm-support-ticket-item-external' : 'fm-support-ticket-item-internal');
    const creator = item.user ? `${item.user.firstName} - ${ moment(item.createdAt).format('h:mma D/M/YY') }` : moment(item.createdAt).format('h:mma D/M/YY');
    return (
      <div className={`fm-support-ticket-item ${source}`} key={key}>
        {
          item.type === 'IMAGE' ? (
            <div className="fm-support-ticket-item-content">
              <img className="fm-support-ticket-item-content-img" alt="Support Content" src={ item.content } />
            </div>
          ) : (
            this.isUrl(item.content) ? (
              <div className="fm-support-ticket-item-content">
                <a className="fm-support-ticket-item-content-link" href={ item.content } target="_blank" rel="noreferrer">{ item.content }</a>
              </div>
            ) : (
              <div className="fm-support-ticket-item-content">{ item.content }</div>
            )
          )
        }
        <p className="fm-support-ticket-item-creator">{ creator }</p>
      </div>
    );
  }

  renderPopOption(option, key, method) {
    return (
      <div className="fm-support-ticket-pop-option" key={key} onClick={() => method(option.value)}>
        <div className="fm-support-ticket-pop-option-icon">
          <Icon icon={option.icon} />
        </div>
        <div className="fm-support-ticket-pop-option-details">
          <p className="fm-support-ticket-pop-option-title">{ option.label }</p>
          <p className="fm-support-ticket-pop-option-description">{ option.description }</p>
        </div>
      </div>
    );
  }

  render() {
    const {
      loading,
      posting,
      ticket,
      items,
      statistics,
      templates,
      templateName,
      composerContent,
      error,
      regions,
      assignees,
      categoryVisible,
      assignVisible,
      regionVisible,
    } = this.state;

    /* Title */
    const title = ticket.subject || `Support Ticket`;

    /* Incident Link */
    let incidentLink = `/incident/new`;
    if (ticket.associatedTrip) {
      incidentLink = `/incident/new/trip/${ticket.associatedTrip.id}`;
    } else if (ticket.associatedUser) {
      incidentLink = `/incident/new/user/${ticket.associatedUser.id}`;
    } else if (ticket.associatedVehicle) {
      incidentLink = `/incident/new/vehicle/${ticket.associatedVehicle.id}`;
    }

    /* Actions */
    const actions = [
      { onClick: this.handleAdjustVoucher, icon: 'money-bill-alt', title: 'Adjust Vouchers' },
      { to: incidentLink, icon: 'exclamation-triangle', title: 'Create Incident' },
      { onClick: this.showAssign, icon: 'user-plus', title: 'Assign Ticket' },
    ];

    /* Variables */
    const user = ticket.associatedUser;
    const task = ticket.associatedTask;
    const trip = ticket.associatedTrip;
    const vehicle = ticket.associatedVehicle;
    const region = ticket.region;
    const tagColors = { good: '#20bf6b', bad: '#eb4d4b', warning: '#f0932b', ok: '#d5d5d5' };
    const status = ticket.resolvedAt ? 'resolved' : (ticket.assignedUser ? 'open' : 'unassigned');
    const popVisible = categoryVisible || assignVisible || regionVisible;
    const ticketRatio = (statistics.recentTickets && statistics.recentTrips) ? (statistics.recentTickets / statistics.recentTrips) : false;

    /* Render */
    return (
      <div className="fm-support-ticket">
        <NavigationBar title={title} loading={loading} showBack={true} rightActions={actions} />
        <div className="fm-support-ticket-content">
          <div className="fm-support-ticket-tags">
            <div className="fm-support-ticket-tag fm-support-ticket-tag-actionable" onClick={this.showAssign}>
              <div className="fm-support-ticket-tag-status" style={{ backgroundColor: colors.ticket[status] }}></div>
              <p className="fm-support-ticket-tag-label">{ ticket.assignedUser ? `${status} - ${ticket.assignedUser.firstName} ${ticket.assignedUser.lastName}` : status }</p>
            </div>
            {
              ticket.source &&
              <div className="fm-support-ticket-tag">
                <div className="fm-support-ticket-tag-status" style={{ backgroundColor: colors.support[ticket.source] }}></div>
                <p className="fm-support-ticket-tag-label">{ ticket.source.toLowerCase() }</p>
              </div>
            }
            {
              task ? (
                <div className="fm-support-ticket-tag fm-support-ticket-tag-actionable" onClick={this.attachTask}>
                  <div className="fm-support-ticket-tag-status" style={{ backgroundColor: taskColor[task.status] }}></div>
                  <p className="fm-support-ticket-tag-label">Task - { task.status }</p>
                </div>
              ) : (
                <div className="fm-support-ticket-tag fm-support-ticket-tag-actionable" onClick={this.attachTask}>
                  <div className="fm-support-ticket-tag-status"></div>
                  <p className="fm-support-ticket-tag-label">No Task</p>
                </div>
              )
            }
            <div className="fm-support-ticket-tag fm-support-ticket-tag-actionable" onClick={this.showCategory}>
              <div className="fm-support-ticket-tag-status" style={{ backgroundColor: ticket.category ? tagColors.ok : tagColors.bad }}></div>
              <p className="fm-support-ticket-tag-label">{ ticket.category || 'Category Not Set' }</p>
            </div>
            <div className="fm-support-ticket-tag fm-support-ticket-tag-actionable" onClick={this.showRegion}>
              <div className="fm-support-ticket-tag-status" style={{ backgroundColor: region ? tagColors.ok : tagColors.bad }}></div>
              <p className="fm-support-ticket-tag-label">{ region ? region.name : 'Region Not Set' }</p>
            </div>
            {
              (user && user.hasSubscription) &&
              <div className="fm-support-ticket-tag">
                <div className="fm-support-ticket-tag-status" style={{ backgroundColor: '#ff206e' }}></div>
                <p className="fm-support-ticket-tag-label">Active Go Pass</p>
              </div>
            }
            {
              ticketRatio > 0.20 ? (
                <div className="fm-support-ticket-tag" title="This statistics is based on the number of support tickets created for each trip taken by the user.">
                  <div className="fm-support-ticket-tag-status" style={{ backgroundColor: ticketRatio > 0.75 ? tagColors.bad : tagColors.warning }}></div>
                  <p className="fm-support-ticket-tag-label">{ ticketRatio > 0.75 ? 'Very High Ticket/Trip Ratio' : (ticketRatio > 0.5 ? 'High Ticket/Trip Ratio' : 'Moderate Ticket/Trip Ratio') }</p>
                </div>
              ) : (
                statistics.recentTickets > 20 &&
                <div className="fm-support-ticket-tag" title="This statistics is based on the number of support tickets in the past 6 months.">
                  <div className="fm-support-ticket-tag-status" style={{ backgroundColor: tagColors.bad }}></div>
                  <p className="fm-support-ticket-tag-label">High Tickets</p>
                </div>
              )
            }
            {
              (!ticket.resolvedAt && statistics.openTickets > 0) &&
              <div className="fm-support-ticket-tag">
                <div className="fm-support-ticket-tag-status" style={{ backgroundColor: tagColors.bad }}></div>
                <p className="fm-support-ticket-tag-label">{ statistics.openTickets + 1 } Open Tickets</p>
              </div>
            }
            <p className="fm-support-ticket-created">{ moment(ticket.createdAt).format('h:mma D/M/YY') }</p>
          </div>
          <div className="fm-support-ticket-relations">
            <div className="fm-support-ticket-relation">
              <table className="fm-support-ticket-relation-table">
                <tbody>
                  <tr className="fm-support-ticket-relation-row">
                    <td className="fm-support-ticket-relation-label">
                      {
                        user
                        ? <Link className="fm-support-ticket-relation-link" to={`/user/${user.id}`}>{ user.firstName } { user.lastName }</Link>
                        : (ticket.name || 'Unknown Name')
                      }
                    </td>
                  </tr>
                  {
                    ticket.phone &&
                    <tr className="fm-support-ticket-relation-row">
                      <td className="fm-support-ticket-relation-value">
                        <Link className="fm-support-ticket-relation-link" to={`/call/${ticket.phone}`}>{ ticket.phone }</Link>
                      </td>
                    </tr>
                  }
                  {
                    ticket.email &&
                    <tr className="fm-support-ticket-relation-row">
                      <td className="fm-support-ticket-relation-value">
                        <a className="fm-support-ticket-relation-link" target="_blank" rel="noreferrer" href={`mailto:${ticket.email}`}>{ ticket.email }</a>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            {
              trip &&
              <div className="fm-support-ticket-relation">
                <table className="fm-support-ticket-relation-table">
                  <tbody>
                    <tr className="fm-support-ticket-relation-row">
                      <td className="fm-support-ticket-relation-label" colSpan="2">
                        <Link className="fm-support-ticket-relation-link" to={`/trip/${trip.id}`}>Trip at { moment(trip.startTime).format('h:mma D/M/YY') }</Link>
                      </td>
                    </tr>
                    <tr className="fm-support-ticket-relation-row">
                      <td className="fm-support-ticket-relation-value">
                        <Link className="fm-support-ticket-relation-link" to={`/trip/${trip.id}`}>Duration:</Link>
                      </td>
                      <td className="fm-support-ticket-relation-value">
                        <Link className="fm-support-ticket-relation-link" to={`/trip/${trip.id}`}>{ (trip.duration / 60).toFixed() } mins</Link>
                      </td>
                    </tr>
                    <tr className="fm-support-ticket-relation-row">
                      <td className="fm-support-ticket-relation-value">
                        <Link className="fm-support-ticket-relation-link" to={`/trip/${trip.id}`}>Cost:</Link>
                      </td>
                      <td className="fm-support-ticket-relation-value">
                        <Link className="fm-support-ticket-relation-link" to={`/trip/${trip.id}`}>${ (trip.actualCost ? (trip.actualCost / 100).toFixed(2) : '0.00') }</Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
            {
              (!trip && task) &&
              <div className="fm-support-ticket-relation">
                <table className="fm-support-ticket-relation-table">
                  <tbody>
                    <tr className="fm-support-ticket-relation-row">
                      <td className="fm-support-ticket-relation-label" colSpan="2">
                        <Link className="fm-support-ticket-relation-link" to={`/task/${task.id}`}>Task #{ task.id }</Link>
                      </td>
                    </tr>
                    <tr className="fm-support-ticket-relation-row">
                      <td className="fm-support-ticket-relation-value">
                        <Link className="fm-support-ticket-relation-link" to={`/task/${task.id}`}>Status:</Link>
                      </td>
                      <td className="fm-support-ticket-relation-value">
                        <Link className="fm-support-ticket-relation-link" to={`/task/${task.id}`}>{ task.status }</Link>
                      </td>
                    </tr>
                    {
                      task.collectedAt &&
                      <tr className="fm-support-ticket-relation-row">
                        <td className="fm-support-ticket-relation-value">
                          <Link className="fm-support-ticket-relation-link" to={`/task/${task.id}`}>Collected At:</Link>
                        </td>
                        <td className="fm-support-ticket-relation-value">
                          <Link className="fm-support-ticket-relation-link" to={`/task/${task.id}`}>{ moment(task.collectedAt).format('h:mma D/MM/YY') }</Link>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            }
            {
              vehicle &&
              <div className="fm-support-ticket-relation">
                <table className="fm-support-ticket-relation-table">
                  <tbody>
                    <tr className="fm-support-ticket-relation-row">
                      <td className="fm-support-ticket-relation-label" colSpan="2">
                        <Link className="fm-support-ticket-relation-link" to={`/vehicle/${vehicle.id}`}>{ vehicleType[vehicle.type] } { vehicle.registration }</Link>
                      </td>
                    </tr>
                    <tr className="fm-support-ticket-relation-row">
                      <td className="fm-support-ticket-relation-value">
                        <Link className="fm-support-ticket-relation-link" to={`/vehicle/${vehicle.id}`}>Status:</Link>
                      </td>
                      <td className="fm-support-ticket-relation-value">
                        <Link className="fm-support-ticket-relation-link" to={`/vehicle/${vehicle.id}`}>{ vehicle.status }</Link>
                      </td>
                    </tr>
                    <tr className="fm-support-ticket-relation-row">
                      <td className="fm-support-ticket-relation-value">
                        <Link className="fm-support-ticket-relation-link" to={`/vehicle/${vehicle.id}`}>Last Inspxn:</Link>
                      </td>
                      <td className="fm-support-ticket-relation-value">
                        <Link className="fm-support-ticket-relation-link" to={`/vehicle/${vehicle.id}`}>{ moment(ticket.associatedVehicle.lastInspection).format('h:mma D/MM/YY') }</Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
          </div>
          {
            (user && user.notes) &&
            <p className="fm-support-ticket-notes"><span className="fm-support-ticket-notes-label">Notes:</span> { user.notes }</p>
          }
          <div className="fm-support-ticket-items">
            <div className="fm-support-ticket-items-list">
              { items.map(this.renderItem) }
            </div>
          </div>
          <div className="fm-support-ticket-responder">
            <div className="fm-support-ticket-responder-composer">
              <textarea className="fm-input fm-input-textarea fm-support-ticket-responder-composer-textarea" value={composerContent} onChange={this.onContentChange} okey="details" disabled={posting}></textarea>
              { posting && <LoadingLine /> }
              <div className="fm-support-ticket-responder-composer-footer">
                <div className="fm-support-ticket-responder-composer-template">
                  <div className="fm-support-ticket-responder-composer-template-icon">
                    <Icon icon="pen-square" />
                  </div>
                  <p className="fm-support-ticket-responder-composer-template-name">{ templateName || 'Select Template' }</p>
                  <select className="fm-support-ticket-responder-composer-template-select" onChange={this.handleTemplate} defaultValue="" disabled={posting}>
                    <option value="" disabled hidden>Select Template</option>
                    { templates.map((template, i) => <option key={i} value={i}>{template.name}</option>) }
                  </select>
                </div>
                <button className="fm-support-ticket-responder-composer-chatgpt" title="ChatGPT" disabled={posting} onClick={this.onChatGPT}>
                  <img className="fm-support-ticket-responder-composer-chatgpt-image" alt="ChatGPT" src="/assets/images/chatgpt.svg" />
                </button>
              </div>
            </div>
            <div className="fm-support-ticket-responder-actions">
              <div className="fm-support-ticket-responder-actions-side">
                <button className="fm-support-ticket-responder-action fm-support-ticket-responder-action-note" title="Post as Note" disabled={posting} onClick={this.onNote}>
                  <Icon icon="sticky-note" />
                </button>
                {
                  ticket.resolvedAt ? (
                    <button className="fm-support-ticket-responder-action fm-support-ticket-responder-action-resolve" disabled={posting} onClick={this.onReopen} title="Reopen ticket.">Reopen</button>
                  ) : (
                    <button className="fm-support-ticket-responder-action fm-support-ticket-responder-action-resolve" disabled={posting} onClick={this.onResolve} title="Resolve ticket. Hold Shift to load the next ticket.">Resolve</button>
                  )
                }
              </div>
              {
                ticket.email &&
                <div className="fm-support-ticket-responder-actions-side">
                  <button className="fm-support-ticket-responder-action fm-support-ticket-responder-action-send" disabled={posting} onClick={this.onSend}>Send</button>
                  { !ticket.resolvedAt && <button className="fm-support-ticket-responder-action fm-support-ticket-responder-action-ressend" disabled={posting} onClick={this.onResolveSend} title="Resolve ticket and send response. Hold Shift to load the next ticket.">Resolve + Send</button> }
                </div>
              }
            </div>
          </div>
        </div>
        {
          popVisible &&
          <div className="fm-support-ticket-pop" ref={this.popBackground} onClick={this.popBackgroundClick}>
            <div className="fm-support-ticket-pop-box">
              {
                categoryVisible &&
                <>
                  <div className="fm-support-ticket-pop-header">
                    <p className="fm-support-ticket-pop-title">Ticket Category</p>
                    <p className="fm-support-ticket-pop-description">Select a category for this ticket.</p>
                  </div>
                  <div className="fm-support-ticket-pop-options">
                    { categories.map((c, i) => this.renderPopOption(c, i, this.handleCategory)) }
                  </div>
                </>
              }
              {
                regionVisible &&
                <>
                  <div className="fm-support-ticket-pop-header">
                    <p className="fm-support-ticket-pop-title">Ticket Region</p>
                    <p className="fm-support-ticket-pop-description">Select a region for this ticket.</p>
                  </div>
                  <div className="fm-support-ticket-pop-options">
                    { regions.map((r, i) => this.renderPopOption(r, i, this.handleRegion)) }
                  </div>
                </>
              }
              {
                assignVisible &&
                <>
                  <div className="fm-support-ticket-pop-header">
                    <p className="fm-support-ticket-pop-title">Assign Ticket</p>
                    <p className="fm-support-ticket-pop-description">Select a team member to assign this ticket.</p>
                  </div>
                  <div className="fm-support-ticket-pop-options">
                    { assignees.map((a, i) => this.renderPopOption(a, i, this.handleAssign)) }
                  </div>
                </>
              }
              <div className="fm-support-ticket-pop-footer">
                { ticket.assignedUser && <button className="fm-support-ticket-pop-destructive" onClick={this.handleUnassign}>Unassign</button> }
                <button className="fm-support-ticket-pop-cancel" onClick={this.hidePop}>Dismiss</button>
              </div>
            </div>
          </div>
        }
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default SupportTicket;
