import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import FileUploader from 'components/common/file-uploader';
import history from 'helpers/history';
import api from 'helpers/api';

class ReportTripDetails extends Component {
  constructor(props) {
    super(props);

    const preloadedType = props.location.state ? props.location.state.type : false;
    this.state = {
      category: props.match.params.category,
      notes: '',
      fileProgress: 100,
      type: preloadedType || {},
      zones: [],
      zone: 0,
      loading: !preloadedType,
      createSupportTicket: false,
    };

    this.loadType = this.loadType.bind(this);
    this.zoneCheck = this.zoneCheck.bind(this);
    this.loadZones = this.loadZones.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.handleFileProgress = this.handleFileProgress.bind(this);
    this.handleSupportTicketToggle = this.handleSupportTicketToggle.bind(this);
    this.onFile = this.onFile.bind(this);
  }

  componentDidMount() {
    document.title = 'Report Trip Details | Flamingo Admin';

    const { type } = this.state;
    if (type.id) {
      return this.zoneCheck();
    } else {
      return this.loadType(this.props.match.params.typeId);
    }
  }

  loadType(typeId) {
    return api.get(`/report/type/${typeId}`)
      .then((res) => this.setState({ type: res.data.data, loading: false }, this.zoneCheck))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  zoneCheck() {
    const { type } = this.state;
    if (type.zoneRequired) {
      this.setState({ loading: true });
      return this.loadZones();
    }
  }

  loadZones() {
    return api.get('/zone/area?active=true&type[]=LOWSPEEDNOPARKING&type[]=NOPARKING&type[]=NOHIRE&type[]=NORIDING')
      .then((res) => this.setState({ zones: res.data.data, loading: false }))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  handleReport() {
    const tripId = this.props.match.params.tripId;
    const tripReportTypeId = this.props.match.params.typeId;
    const { notes, zone, photoUrl, createSupportTicket, fileProgress, loading } = this.state;

    if (loading) {
      return;
    }

    if (fileProgress < 100) {
      return window.alert('Please wait, your photo is still uploading.');
    }
    if (notes.length === 0 && createSupportTicket) {
      return window.alert('Please include a note.');
    }

    this.setState({ loading: true });
    const data = { tripReportTypeId, photoUrl, tripId, createSupportTicket };
    if (notes.length > 0) {
      data.notes = notes;
    }
    if (zone > 0) {
      data.zoneId = zone;
    }
    api.post(`/report`, data)
      .then((res) => alert(`Report created successfully.`))
      .then(() => history.go(-3))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  handleNotesChange(e) {
    const notes = e.target.value;
    this.setState({ notes });
  }

  handleZoneChange(e) {
    const zone = e.target.value;
    this.setState({ zone });
  }

  onFile(file) {
    this.setState({ photoUrl: file.url });
  }

  handleFileProgress(fileProgress) {
    console.log(fileProgress);
    this.setState({ fileProgress });
  }

  handleSupportTicketToggle(e) {
    const { createSupportTicket } = this.state;
    this.setState({ createSupportTicket: !createSupportTicket });
  }

  renderZone(zone, i) {
    let name = zone.name;
    if (zone.startTime !== '00:00:00' || zone.endTime !== '24:00:00') {
      name += ` (${ zone.startTime.substring(0, 5) } to ${ zone.endTime.substring(0, 5) })`;
    }
    return <option key={i} value={zone.id}>{name}</option>;
  }

  render() {
    const { loading, category, type, notes, zones, zone, createSupportTicket } = this.state;
    return (
      <div className="fm-report-trip-details">
        <NavigationBar title="Trip Report Details" subtitle={category.toUpperCase()} loading={loading} showBack={true} />
        <div className="fm-report-trip-details-content">
          { type.title && <p className="fm-report-trip-details-title">{ type.title } Report</p> }
          {
            type.zoneRequired ? (
              <>
                <p className="fm-report-trip-details-info">Select a zone below. Notes and images are optional but recommended.</p>
                <select className="fm-input fm-input-select" value={zone} onChange={this.handleZoneChange}>
                  { zones.map(this.renderZone) }
                </select>
              </>
            ) : (
              category !== 'riding' ? (
                type.photoRequired === 'optional' ? (
                  <p className="fm-report-trip-details-info">Notes are optional. Images are required for this type of report if the user's parking photo doesn't clearly show the issue.</p>
                ) : (
                  type.photoRequired === 'required' ? (
                    <p className="fm-report-trip-details-info">Notes are optional. Images are required for this type of report.</p>
                  ) : (
                    <p className="fm-report-trip-details-info">Notes are optional. Images are not required for this type of report.</p>
                  )
                )
              ) : (
                <p className="fm-report-trip-details-info">Notes and images are optional but recommended.</p>
              )
            )
          }
          <input type="text" autoFocus={true} className="fm-input" value={notes} onChange={this.handleNotesChange} placeholder="Notes (Optional)" />
          { type.photoRequired !== 'unnecessary' && <FileUploader accept="images" type="report" resize={1800} onFinish={this.onFile} onProgress={this.handleFileProgress} /> }
          <div className="fm-report-trip-details-support">
            <label className="fm-report-trip-details-support-label" htmlFor="fm-report-support-checkbox">Create Support Ticket</label>
            <label className="fm-input-checkbox-container">
              <input className="fm-input fm-input-checkbox" id="fm-report-support-checkbox" type="checkbox" checked={createSupportTicket} onChange={this.handleSupportTicketToggle} />
              <span className="fm-input-checkbox-checkmark"></span>
            </label>
          </div>
          <button className="fm-report-trip-details-button" onClick={this.handleReport}>{ loading ? 'Loading...' : 'Submit Report' }</button>
          <p className="fm-report-trip-details-note">Tick the "Create Support Ticket" option if additional action is required, for example the scooter can't be recovered due to being on private property. Ensure the note is clear for what action is required.</p>
        </div>
      </div>
    );
  }
}

export default ReportTripDetails;
