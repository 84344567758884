import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Marker, Polygon } from 'components/common/map';
import moment from 'moment';
import history from 'helpers/history';
import api from 'helpers/api';
import colors from 'helpers/colors';

const baseUrl = 'https://storage.googleapis.com/flamingo-static/images/admin/';
const icons = {
  full: { url: baseUrl + 'hub-full.png', size: new window.google.maps.Size(42, 42), scaledSize: new window.google.maps.Size(42, 42), anchor: new window.google.maps.Point(21, 21) },
  partial: { url: baseUrl + 'hub-partial.png', size: new window.google.maps.Size(42, 42), scaledSize: new window.google.maps.Size(42, 42), anchor: new window.google.maps.Point(21, 21) },
  empty: { url: baseUrl + 'hub-empty.png', size: new window.google.maps.Size(42, 42), scaledSize: new window.google.maps.Size(42, 42), anchor: new window.google.maps.Point(21, 21) },
  fullScooter: { url: baseUrl + 'hub-full-scooter.png', size: new window.google.maps.Size(42, 42), scaledSize: new window.google.maps.Size(42, 42), anchor: new window.google.maps.Point(21, 21) },
  partialScooter: { url: baseUrl + 'hub-partial-scooter.png', size: new window.google.maps.Size(42, 42), scaledSize: new window.google.maps.Size(42, 42), anchor: new window.google.maps.Point(21, 21) },
  emptyScooter: { url: baseUrl + 'hub-empty-scooter.png', size: new window.google.maps.Size(42, 42), scaledSize: new window.google.maps.Size(42, 42), anchor: new window.google.maps.Point(21, 21) },
  fullBike: { url: baseUrl + 'hub-full-bike.png', size: new window.google.maps.Size(42, 42), scaledSize: new window.google.maps.Size(42, 42), anchor: new window.google.maps.Point(21, 21) },
  partialBike: { url: baseUrl + 'hub-partial-bike.png', size: new window.google.maps.Size(42, 42), scaledSize: new window.google.maps.Size(42, 42), anchor: new window.google.maps.Point(21, 21) },
  emptyBike: { url: baseUrl + 'hub-empty-bike.png', size: new window.google.maps.Size(42, 42), scaledSize: new window.google.maps.Size(42, 42), anchor: new window.google.maps.Point(21, 21) },
};

class HubMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hubs: [],
      regions: [],
      tiers: [],
      loading: true,
      includeUnapproved: !!props.match.params.includeUnapproved,
      targetKey: moment().add(3, 'hours').format('dddd').toLowerCase(),
    };

    this.loadHubs = this.loadHubs.bind(this);
    this.loadRegions = this.loadRegions.bind(this);
    this.refreshHubs = this.refreshHubs.bind(this);
    this.zeroTarget = this.zeroTarget.bind(this);
    this.renderMarker = this.renderMarker.bind(this);
  }

  componentDidMount() {
    document.title = 'Hub Map | Flamingo Admin';
    localStorage.setItem('fm-hub', 'map');
    this.loadHubs();
    this.loadRegions();
  }

  loadHubs() {
    this.setState({ loading: true });
    return api.get(`/hub${ this.state.includeUnapproved ? '?includeUnapproved=true' : '' }`)
      .then((res) => this.setState({ hubs: res.data.data, loading: false }))
      .catch(console.log);
  }

  loadRegions() {
    return api.get('/region/service-area')
    .then((res) => this.setState({ regions: res.data.data, tiers: res.data.data.flatMap(region => region.tiers || []), loading: false }))
      .catch(console.log);
  }

  refreshHubs() {
    if (this.state.loading) {
      return;
    }
    // Check when it was last updated
    const difference = moment().diff(Math.min.apply(null,this.state.hubs.map(a => new Date(a.occupancyUpdatedAt))), 'minutes');
    if (difference >= 5 || window.confirm(`Data was last updated ${difference} minutes ago. Are you sure you want to update?`)) {
      // Refresh the hubs
      this.setState({ loading: true });
      return api.post('/hub')
        .then((res) => this.setState({ hubs: res.data.data, loading: false }))
        .catch(console.log);
    }
  }

  zeroTarget(hub) {
    return hub.targets.filter((target) => target.day === this.state.targetKey).reduce((a, c) => c.target + a, 0) > 0;
  }

  renderMarker(hub, i) {
    const dayTargets = hub.targets.filter((target) => target.day === this.state.targetKey)
    const target = dayTargets.reduce((a, c) => c.target + a, 0);
    const scooterTarget = dayTargets.filter((target) => target.vehicleType === 'scooter').reduce((a, c) => c.target + a, 0);
    const bikeTarget = dayTargets.filter((target) => target.vehicleType === 'bike').reduce((a, c) => c.target + a, 0);

    const percentage = target === 0 ? 1 : hub.occupancy / target;
    const scooterPercent = scooterTarget === 0 ? 0 : hub.scooterOccupancy / scooterTarget;
    const bikePercent = bikeTarget === 0 ? 0 : hub.bikeOccupancy / bikeTarget;

    let icon = percentage > 0.8 ? icons.full : (percentage > 0.5 ? icons.partial : icons.empty);

    if (scooterTarget > 0 && bikeTarget > 0) {
      if (scooterPercent > 0.8 && bikePercent < 0.8) {
        icon = bikePercent > 0.5 ? icons.partialBike : icons.emptyBike;
      } else if (bikePercent > 0.8 && scooterPercent < 0.8) {
        icon = scooterPercent > 0.5 ? icons.partialScooter : icons.emptyScooter;
      } else if (scooterPercent > 0.5 && bikePercent < 0.5) {
        icon = icons.emptyBike;
      } else if (bikePercent > 0.5 && scooterPercent < 0.5) {
        icon = icons.emptyScooter;
      }
    } else if (scooterTarget > 0) {
      icon = scooterPercent > 0.8 ? icons.fullScooter : (scooterPercent > 0.5 ? icons.partialScooter : icons.emptyScooter);
    } else if (bikeTarget > 0) {
      icon = bikePercent > 0.8 ? icons.fullBike : (bikePercent > 0.5 ? icons.partialBike : icons.emptyBike);
    }

    if (!hub.approved) {
      icon = null;
    }

    return (
      <Marker
        key={i}
        position={{ lat: hub.latitude, lng: hub.longitude }}
        title={`${hub.name} - ${hub.occupancy}/${target}`}
        icon={icon}
        onClick={() => history.push({ pathname: `/hub/${hub.id}`, state: { hub } })}
      />
    );
  }

  render() {
    const { loading, hubs, regions, tiers } = this.state;
    const addAction = { to: '/hub/add', icon: 'plus-circle' };
    const listAction = { to: '/hub/list', icon: 'list' };
    const refreshAction = { onClick: this.refreshHubs, icon: 'sync-alt' };

    return (
      <div className="fm-hub-map">
        <NavigationBar title="Hub Map" rightActions={[addAction, listAction, refreshAction]} loading={loading} />
        <div className="fm-hub-map-holder">
          <Map google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
            { hubs.filter(this.zeroTarget).map(this.renderMarker) }
            { regions.map((region, i) => <Polygon key={i} paths={ region.serviceArea.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeColor={colors.zone.serviceArea} strokeWeight={2} fillOpacity={0} clickable={false} />) }
            { tiers.map((tier, i) => <Polygon key={i} paths={ tier.area.map((p) => ({ lat: p[0], lng: p[1] })) } strokeColor={'rgba(52, 152, 219, 0.5)'} strokeWeight={2} fillOpacity={0} clickable={false} />) }
          </Map>
        </div>
      </div>
    );
  }
}

export default HubMap;
