import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class ReportParkingStatistics extends Component {
  constructor() {
    super();
    const today = moment().format('YYYY-MM')
    this.state = { month: today, today, data: false, loading: false };

    this.loadData = this.loadData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderRegion = this.renderRegion.bind(this);
  }

  componentDidMount() {
    document.title = 'Parking Statistics | Flamingo Admin';
  }

  loadData() {
    this.setState({ loading: true });
    const month = this.state.month;
    return api.get(`/report/parking/statistics?month=${month}`)
      .then((res) => this.setState({ data: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  handleDateChange(e) {
    this.setState({ month: e.target.value });
  }

  renderType(type, i, tripsReviewed) {
    return (
      <div className="fm-report-parking-statistics-region-type" key={i}>
        <p className="fm-report-parking-statistics-region-type-title">{ type.title }</p>
        <p className="fm-report-parking-statistics-region-type-description">{ type.description }</p>
        <p className="fm-report-parking-statistics-region-type-total">{ type.total } ({ (type.total / tripsReviewed * 100).toFixed(1) }%)</p>
      </div>
    );
  }

  renderRegion(region, i) {
    /* ----- Trip Stats ----- */
    const tripsReviewed = region.goodTrips + region.badTrips;
    const tripsPercentReviewed = tripsReviewed / region.totalTrips;
    const tripsPercentTarget = tripsPercentReviewed / region.target;
    const tripsPercentTargetColor = tripsPercentTarget >= 1 ? '#4cd137' : (tripsPercentTarget >= 0.75 ? '#ffbe13' : '#e84118');
    /* ----- Parking Detection Stats ----- */
    const parkingDetection = region.totalParkingDetection / region.totalTrips;
    const withDetectionOrReview = region.totalTrips - region.totalNoDetectionOrReview;
    const withDetectionOrReviewPercent = withDetectionOrReview / region.totalTrips;
    /* ----- User Stats ----- */
    const usersPercentReviewed = region.reviewedUsers / region.totalUsers;
    /* ----- Good/Bad Stats ----- */
    const goodTripsPercent = region.goodTrips / tripsReviewed;
    const goodUsersPercent = region.goodUsers / region.reviewedUsers;
    return (
      <div className="fm-report-parking-statistics-region" key={i}>
        <p className="fm-report-parking-statistics-region-name">{ region.name }</p>
        <div className="fm-report-parking-statistics-region-items">
          { /* Trips Reviewed */ }
          <div className="fm-report-parking-statistics-region-item">
            <div className="fm-report-parking-statistics-region-bar">
              <div className="fm-report-parking-statistics-region-bar-inner" style={{ width: `${Math.min(parseInt(tripsPercentTarget * 100), 100)}%`, backgroundColor: tripsPercentTargetColor }}></div>
              <p className="fm-report-parking-statistics-region-bar-value">{ (tripsPercentTarget * 100).toFixed() }%</p>
            </div>
            <p className="fm-report-parking-statistics-region-item-label">{ (tripsPercentReviewed * 100).toFixed() }% of trips manually reviewed ({ tripsReviewed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') } / { region.totalTrips.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }), { (tripsPercentTarget * 100).toFixed() }% of the target ({ (region.target * 100).toFixed() }%).</p>
          </div>
          { /* Parking Detection */ }
          <div className="fm-report-parking-statistics-region-item">
            <div className="fm-report-parking-statistics-region-bar">
              <div className="fm-report-parking-statistics-region-bar-inner" style={{ width: `${Math.min(parseInt(parkingDetection * 100), 100)}%`, backgroundColor: '#4cd137' }}></div>
              <p className="fm-report-parking-statistics-region-bar-value">{ (parkingDetection * 100).toFixed() }%</p>
            </div>
            <p className="fm-report-parking-statistics-region-item-label">{ (parkingDetection * 100).toFixed() }% of trips automatically reviewed ({ region.totalParkingDetection.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') } / { region.totalTrips.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }).</p>
          </div>
          { /* No Review */ }
          <div className="fm-report-parking-statistics-region-item">
            <div className="fm-report-parking-statistics-region-bar">
              <div className="fm-report-parking-statistics-region-bar-inner" style={{ width: `${Math.min(parseInt(withDetectionOrReviewPercent * 100), 100)}%`, backgroundColor: '#4cd137' }}></div>
              <p className="fm-report-parking-statistics-region-bar-value">{ (withDetectionOrReviewPercent * 100).toFixed() }%</p>
            </div>
            <p className="fm-report-parking-statistics-region-item-label">{ (withDetectionOrReviewPercent * 100).toFixed() }% of trips automatically or manually reviewed ({ withDetectionOrReview.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') } / { region.totalTrips.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }).</p>
          </div>
          { /* Users Reviewed */ }
          <div className="fm-report-parking-statistics-region-item">
            <div className="fm-report-parking-statistics-region-bar">
              <div className="fm-report-parking-statistics-region-bar-inner" style={{ width: `${Math.min(parseInt(usersPercentReviewed * 100), 100)}%`, backgroundColor: '#4cd137' }}></div>
              <p className="fm-report-parking-statistics-region-bar-value">{ (usersPercentReviewed * 100).toFixed() }%</p>
            </div>
            <p className="fm-report-parking-statistics-region-item-label">{ (usersPercentReviewed * 100).toFixed() }% of users manually reviewed ({ region.reviewedUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') } / { region.totalUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }).</p>
          </div>
          { /* Good/Bad Trip Ratio */ }
          <div className="fm-report-parking-statistics-region-item">
            <div className="fm-report-parking-statistics-region-bar">
              <div className="fm-report-parking-statistics-region-bar-inner" style={{ width: `${Math.min(parseInt(goodTripsPercent * 100), 100)}%`, backgroundColor: '#4cd137' }}></div>
              <p className="fm-report-parking-statistics-region-bar-value">{ (goodTripsPercent * 100).toFixed() }%</p>
            </div>
            <p className="fm-report-parking-statistics-region-item-label">{ (goodTripsPercent * 100).toFixed() }% of manually reviewed trips deemed good ({ region.goodTrips.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }), { ((1 - goodTripsPercent) * 100).toFixed() }% of trips deemed bad.</p>
          </div>
          { /* Good/Bad User Ratio */ }
          <div className="fm-report-parking-statistics-region-item">
            <div className="fm-report-parking-statistics-region-bar">
              <div className="fm-report-parking-statistics-region-bar-inner" style={{ width: `${Math.min(parseInt(goodUsersPercent * 100), 100)}%`, backgroundColor: '#4cd137' }}></div>
              <p className="fm-report-parking-statistics-region-bar-value">{ (goodUsersPercent * 100).toFixed() }%</p>
            </div>
            <p className="fm-report-parking-statistics-region-item-label">{ (goodUsersPercent * 100).toFixed() }% of manually reviewed users deemed good ({ region.goodUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }), { ((1 - goodUsersPercent) * 100).toFixed() }% of users deemed bad.</p>
          </div>
          { /* Breakdown */ }
          <div className="fm-report-parking-statistics-region-types">
            { region.types.map((t, i) => this.renderType(t, i, tripsReviewed)) }
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loading, data, month, today } = this.state;

    return (
      <div className="fm-report-parking-statistics">
        <NavigationBar title="Parking Review Statistics" loading={loading} showBack={true} />
        <div className="fm-report-parking-statistics-content">
          <div className="fm-report-parking-statistics-form">
            <input value={month} className="fm-input" type="month" onChange={this.handleDateChange} max={today} />
            <button className="fm-report-parking-statistics-button" onClick={this.loadData}>{ loading ? 'Loading...' : 'Load Data' }</button>
          </div>
          {
            data ? (
              <div className="fm-report-parking-statistics-report">
                { data.map(this.renderRegion) }
              </div>
            ) : (
              <p className="fm-report-parking-statistics-empty">{ loading ? 'Please wait...' : 'Select the month above.' }</p>
            )
          }
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default ReportParkingStatistics;
