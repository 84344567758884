import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Toast from 'components/common/toast';
import NavigationBar from 'components/common/navigation-bar';
import Input from 'components/common/input';
import api from 'helpers/api';

class DocumentTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      loading: true,
      error: false,
    };

    this.loadDocuments = this.loadDocuments.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderTemplate = this.renderTemplate.bind(this);
  }

  componentDidMount() {
    document.title = `Documents | Flamingo Admin`;
    this.loadDocuments();
  }

  /* NETWORKING */

  loadDocuments() {
    this.setState({ loading: true });
    return api.get(`/document/template`)
      .then((res) => this.setState({ templates: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  /* HANDLERS */

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* RENDERS */

  renderTemplate(template, i) {
    return (
      <Link className="fm-document-templates-item" key={i} to={{ pathname: `/document/${template.id}`, state: { template } }}>
        <p className="fm-document-templates-item-title">{ template.title }</p>
        <p className="fm-document-templates-item-period">{ template.period }</p>
      </Link>
    );
  }

  render() {
    const { templates, loading, error } = this.state;

    return (
      <div className="fm-document-templates">
        <NavigationBar title="Documents" loading={loading} />
        <div className="fm-document-templates-items">
          { templates.map(this.renderTemplate) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default DocumentTemplates;
