import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import colors from 'helpers/colors';
import history from 'helpers/history';
import api from 'helpers/api';

const actionFilters = [
  { value: 'all', label: 'All Reports' },
  { value: 'emailTemplate', label: 'Recommended Action - Email' },
  { value: 'call', label: 'Recommended Action - Call' },
  { value: 'fine', label: 'Recommended Action - Fine' },
  { value: 'suspend', label: 'Recommended Action - Suspend' },
  { value: 'disable', label: 'Recommended Action - Disable' },
];

class ReportPending extends Component {
  constructor(props) {
    super(props);
    this.state = { reports: [], loading: true, actionFilter: this.props.match.params.actionFilter || 'all' };
    this.loadReports = this.loadReports.bind(this);
    this.renderReport = this.renderReport.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  componentDidMount() {
    document.title = 'Reports | Flamingo Admin';
    this.loadReports();
  }

  loadReports() {
    this.setState({ loading: true });
    let endpoint = `/report/pending`;
    const params = [];
    if (this.props.match.params.category) {
      params.push({ key: 'category', value: this.props.match.params.category });
    }
    if (this.state.actionFilter !== 'all') {
      params.push({ key: 'recommendedAction', value: this.state.actionFilter });
    }
    if (params.length > 0) {
      endpoint += `?${params.map((p) => `${p.key}=${p.value}`).join('&')}`;
    }
    return api.get(endpoint)
      .then((res) => this.setState({ reports: res.data.data, loading: false }))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  onFilter(e) {
    const actionFilter = e.target.value;
    history.replace(actionFilter === 'all' ? `/report` : `/report/action/${actionFilter}`);
    this.setState({ actionFilter }, this.loadReports);
  }

  renderReport(report, i) {
    let pathname = `/report/${report.id}`;
    if (this.props.match.params.category) {
      pathname += `/category/${this.props.match.params.category}`;
    } else if (this.state.actionFilter !== 'all') {
      pathname += `/action/${this.state.actionFilter}`;
    }
    return (
      <Link to={{ pathname, state: { report } }} key={i} className="fm-report-pending-item">
        <div className="fm-report-pending-item-header">
          <p className="fm-report-pending-item-title">{ report.type.title }</p>
          <p className="fm-report-pending-item-tag" style={{ backgroundColor: colors.report[report.type.category] }}>{ report.type.category.toUpperCase() }</p>
        </div>
        <div className="fm-report-pending-item-details">
          <p className="fm-report-pending-item-user">{ report.user.firstName } { report.user.lastName }</p>
          <p className="fm-report-pending-item-description">{ report.notes || report.type.description }</p>
          <p className="fm-report-pending-item-time">{ moment(report.trip.startTime).format('h:mma D/M/YY') }</p>
        </div>
      </Link>
    );
  }

  render() {
    const { loading, reports, actionFilter } = this.state;
    const refreshAction = { onClick: this.loadReports, icon: 'sync-alt' };

    return (
      <div className="fm-report-pending">
        <NavigationBar title="Reports" subtitle={ reports.length > 0 ? `${reports.length} Pending` : null } rightActions={[refreshAction]} loading={loading} />
        <div className="fm-report-pending-content">
          <div className="fm-report-pending-select">
            <select className="fm-input fm-input-select" value={actionFilter} onChange={this.onFilter} disabled={loading}>
              { actionFilters.map((f, i) => <option key={i} value={f.value}>{ f.label }</option>) }
            </select>
          </div>
          { reports.map(this.renderReport) }
          { (reports.length === 0 && !loading) && <p className="fm-report-pending-empty">{ actionFilter === 'all' ? 'No pending reports.' : `No pending reports for ${actionFilter}.` }</p> }
        </div>
      </div>
    );
  }
}

export default ReportPending;
