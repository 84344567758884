import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class VoucherView extends Component {
  constructor(props) {
    super(props);
    const preloadedVoucher = props.location.state ? props.location.state.voucher : false;
    this.state = {
      voucher: preloadedVoucher || {},
      loading: true,
      error: false,
      editing: false,
      editVoucher: {},
    };
    this.loadVoucher = this.loadVoucher.bind(this);
    this.onDownload = this.onDownload.bind(this);
    this.onDownloadSingle = this.onDownloadSingle.bind(this);
    this.onDownloadCampaign = this.onDownloadCampaign.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Voucher | Flamingo Admin';
    this.loadVoucher();
  }

  loadVoucher() {
    return api.get(`/voucher/${this.props.match.params.voucherId}`)
      .then((res) => this.setState({ voucher: { ...this.state.voucher, ...res.data.data }, loading: false }))
      .catch(this.handleError);
  }

  onDownloadCampaign() {
    return this.onDownload(this.state.voucher.campaign);
  }

  onDownloadSingle() {
    return this.onDownload();
  }

  onDownload(campaign = undefined) {
    const to = prompt('Enter "To" value or leave blank.');
    const from = prompt('Enter "From" value or leave blank.');
    return api.post(`/voucher/${this.props.match.params.voucherId}/download`, { to, from, campaign }, { responseType: 'blob' })
      .then(this.handleFile)
      .catch(this.handleError);
  }

  handleFile(response) {
    const blob = new Blob([response.data], { type: 'text/html' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = 'flamingo-gift-card.html';
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
  }

  onEdit() {
    const { voucher, editing } = this.state;
    const editVoucher = {
      expires: moment(voucher.expires).format('YYYY-MM-DD'),
      maxRedemptions: voucher.maxRedemptions,
    };
    this.setState({ editing: !editing, editVoucher });
  }

  onChange(e) {
    const editVoucher = this.state.editVoucher;
    editVoucher[e.target.getAttribute('okey')] = e.target.value;
    this.setState({ editVoucher, error: false })
  }

  onUpdate() {
    const { voucher, editVoucher } = this.state;
    this.setState({ loading: true });
    const voucherData = {
      expires: moment(editVoucher.expires).endOf('day').toISOString(),
      maxRedemptions: parseInt(editVoucher.maxRedemptions),
    };
    return api.post(`/voucher/${voucher.id}`, voucherData)
      .then((res) => this.setState({ voucher: { ...this.state.voucher, ...res.data.data }, loading: false, editing: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  render() {
    const { loading, error, voucher, editing, editVoucher } = this.state;

    const title = voucher.code || 'Voucher';
    const valueString = voucher.type === 'CREDIT' ? `$${ (voucher.value / 100).toFixed(2) }` : `${ voucher.value } ${ voucher.type === 'MINUTES' ? 'mins' : 'unlocks' }`;
    const hasExpired = moment().isAfter(voucher.expires);
    const maxedRedemptions = voucher.redemptions >= voucher.maxRedemptions;
    const status = voucher.id ? (maxedRedemptions ? 'Max Redemptions' : (hasExpired ? 'Expired' : 'Active')) : false;
    const editAction = { onClick: this.onEdit, icon: editing ? 'times-circle' : 'edit' };

    return (
      <div className="fm-voucher-view">
        <NavigationBar title={title} loading={loading} showBack={true} rightActions={[editAction]} />
        {
          editing ? (
            <div className="fm-voucher-view-content fm-voucher-view-content-new">
              <div className="fm-voucher-new-input">
                <p className="fm-input-label">Voucher Expiration</p>
                <input value={editVoucher.expires} onChange={this.onChange} okey="expires" className="fm-input" type="date" disabled={loading} />
                <p className="fm-input-hint">The last day the voucher can be redeemed (inclusive).</p>
              </div>
              <div className="fm-voucher-new-input">
                <p className="fm-input-label">Maximum Redemptions</p>
                <input value={editVoucher.maxRedemptions} onChange={this.onChange} okey="maxRedemptions" className="fm-input" type="number" disabled={loading} />
                <p className="fm-input-hint">The maximum times the voucher can be redeemed.</p>
              </div>
              <button className="fm-voucher-new-button" onClick={this.onUpdate} disabled={loading}>{ loading ? 'Loading...' : 'Save Voucher' }</button>
            </div>
          ) : (
            <div className="fm-voucher-view-content">
              <div className="fm-voucher-view-row">
                <p className="fm-voucher-view-row-key">Code:</p>
                { voucher.code && <p className="fm-voucher-view-row-value">{ voucher.code }</p> }
              </div>
              <div className="fm-voucher-view-row">
                <p className="fm-voucher-view-row-key">Status:</p>
                { status && <p className="fm-voucher-view-row-value" style={{ color: status === 'Active' ? '#4cd137' : '#e84118', fontWeight: 600 }}>{ status }</p> }
              </div>
              <div className="fm-voucher-view-row">
                <p className="fm-voucher-view-row-key">Value:</p>
                { voucher.value && <p className="fm-voucher-view-row-value">{ valueString }</p> }
              </div>
              <div className="fm-voucher-view-row">
                <p className="fm-voucher-view-row-key">Expires:</p>
                { voucher.expires && <p className="fm-voucher-view-row-value">{ moment(voucher.expires).format('h:mma D/M/YY') }</p> }
              </div>
              <div className="fm-voucher-view-row">
                <p className="fm-voucher-view-row-key">Redemption Duration:</p>
                { voucher.duration && <p className="fm-voucher-view-row-value">{ voucher.duration > 86400 ? `${parseInt(voucher.duration / 86400)} days` : `${parseInt(voucher.duration / 3600)} hours` }</p> }
              </div>
              <div className="fm-voucher-view-row">
                <p className="fm-voucher-view-row-key">Redemptions:</p>
                { voucher.duration && <p className="fm-voucher-view-row-value">{ voucher.redemptions }</p> }
              </div>
              <div className="fm-voucher-view-row">
                <p className="fm-voucher-view-row-key">Maximum Redemptions:</p>
                { voucher.duration && <p className="fm-voucher-view-row-value">{ voucher.maxRedemptions }</p> }
              </div>
              <div className="fm-voucher-view-row">
                <p className="fm-voucher-view-row-key">Region:</p>
                <p className="fm-voucher-view-row-value">{ voucher.region ? voucher.region.name : 'All' }</p>
              </div>
              <div className="fm-voucher-view-row">
                <p className="fm-voucher-view-row-key">Vehicle Type:</p>
                <p className="fm-voucher-view-row-value">{ voucher.vehicleType || 'All' }</p>
              </div>
              <div className="fm-voucher-view-row">
                <p className="fm-voucher-view-row-key">New Users Only:</p>
                <p className="fm-voucher-view-row-value">{ voucher.newUsersOnly ? 'Yes' : 'No' }</p>
              </div>
              <div className="fm-voucher-view-row">
                <p className="fm-voucher-view-row-key">Bypass Payment Method:</p>
                <p className="fm-voucher-view-row-value">{ voucher.bypassPaymentMethod ? 'Yes' : 'No' }</p>
              </div>
              <div className="fm-voucher-view-row">
                { voucher.notes && <p className="fm-voucher-view-row-terms">Notes: { voucher.notes }</p> }
              </div>
              <div className="fm-voucher-view-row">
                <button className="fm-voucher-view-download" disabled={loading} onClick={this.onDownloadSingle}>Download as Gift Card</button>
                { voucher.campaign && <button className="fm-voucher-view-download" disabled={loading} onClick={this.onDownloadCampaign}>Download Set as Gift Cards</button>}
              </div>
            </div>
          )
        }
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default VoucherView;
