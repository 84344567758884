import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import HubAdd from './components/hub-add/';
import HubCurrent from './components/hub-current/';
import HubMap from './components/hub-map/';
import HubView from './components/hub-view/';
import HubEdit from './components/hub-edit/';

class Hub extends Component {
  render() {
    const lastRoute = `/hub/${ localStorage.getItem('fm-hub') || 'list' }`;
    return (
      <Switch>
        <Route path="/hub/add" component={HubAdd} />
        <Route path="/hub/list" component={HubCurrent} />
        <Route path="/hub/map/:includeUnapproved?" component={HubMap} />
        <Route path="/hub/:hubId/edit" component={HubEdit} />
        <Route path="/hub/:hubId" component={HubView} />
        <Redirect from='/hub' to={lastRoute} />
      </Switch>
    );
  }
}

export default Hub;
