import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Loading from 'components/common/loading';
import Icon from 'components/common/icon';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class ReportParkingReview extends Component {
  constructor(props) {
    super(props);
    const statistics = props.location.state ? props.location.state.statistics : false;
    this.state = {
      trips: [],
      reviewedTrips: [],
      reviewedCount: 0,
      types: [],
      loading: true,
      regionId: props.match.params.regionId,
      month: props.match.params.month,
      allTrips: props.match.params.allTrips === 'all-trips',
      allUsers: props.match.params.allUsers === 'all-users',
      withoutParkingDetection: props.match.params.withoutParkingDetection === 'wpd',
      statistics,
    };

    this.loadTypes = this.loadTypes.bind(this);
    this.loadTrips = this.loadTrips.bind(this);
    this.loadNextTrip = this.loadNextTrip.bind(this);

    this.handleReview = this.handleReview.bind(this);

    this.renderType = this.renderType.bind(this);
  }

  componentDidMount() {
    document.title = 'Parking Review | Flamingo Admin';
    this.loadTypes();
  }

  loadTypes() {
    return api.get(`/report/types/parking`)
      .then((res) => this.setState({ types: res.data.data.sort(this.goodToBad) }, this.loadTrips))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  loadTrips() {
    this.setState({ loading: true });
    const { regionId, month, allTrips, allUsers, withoutParkingDetection } = this.state;
    const queries = [
      { key: 'regionId', value: regionId },
      { key: 'month', value: month },
      { key: 'allTrips', value: allTrips },
      { key: 'allUsers', value: allUsers },
      { key: 'withoutParkingDetection', value: withoutParkingDetection },
    ].filter((q) => !!q.value).map((q) => `${q.key}=${q.value}`).join('&');
    const endpoint = queries.length > 0 ? `/report/parking?${queries}` : '/report/parking';
    return api.get(endpoint)
      .then((res) => this.setState({ trips: res.data.data.filter((trip) => !this.state.reviewedTrips.includes(trip.id)), loading: false, updating: false }))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  /* Controls */

  loadNextTrip() {
    let trips = this.state.trips;
    trips.shift();
    this.setState({ trips });
    if (trips.length === 0) {
      return this.loadTrips();
    }
  }

  handleReview(type, tripReportTypeId) {
    const trip = this.state.trips[0];
    if (!trip) {
      return;
    }

    let { reviewedTrips, reviewedCount } = this.state;
    reviewedTrips.push(trip.id);
    if (['good', 'bad'].includes(type)) {
      ++reviewedCount;
    }
    this.setState({ reviewedTrips, reviewedCount });

    this.flash(type);

    const reviewData = {
      tripId: trip.id,
      tripReportTypeId,
    };
    api.post(`/report/parking`, reviewData);

    return this.loadNextTrip();
  }

  goodToBad(a, b) {
    if (a.parkingReview < b.parkingReview) {
      return 1;
    }
    if (a.parkingReview > b.parkingReview) {
      return -1;
    }
    return 0;
  }

  flash(type) {
    const optionsElem = document.getElementById('fm-report-parking-review-options');
    const imageElem = document.getElementById('fm-report-image');
    imageElem.style.animation = `report-${type} 0.5s ease-in`;
    optionsElem.classList.add('fm-report-parking-review-options-disabled');
    optionsElem.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      imageElem.style.animation = undefined;
      optionsElem.classList.remove('fm-report-parking-review-options-disabled');
    }, 500);
  }

  /* Renders */

  renderType(type, i) {
    const iconClass = type.parkingReview ? `fm-report-parking-review-option-icon fm-report-parking-review-option-icon-${type.parkingReview}` : 'fm-report-parking-review-option-icon';
    return (
      <div className="fm-report-parking-review-option" key={i} onClick={() => this.handleReview(type.parkingReview, type.id)}>
        <div className={iconClass}>
          <Icon icon={type.icon} />
        </div>
        <div className="fm-report-parking-review-option-text">
          <p className="fm-report-parking-review-option-title">{ type.title }</p>
          <p className="fm-report-parking-review-option-description">{ type.description }</p>
        </div>
      </div>
    );
  }

  render() {
    const {
      trips,
      types,
      loading,
      error,
      statistics,
      reviewedCount,
    } = this.state;

    const trip = trips[0];
    const percentReviewed = statistics ? (((statistics.totalReviewed + reviewedCount) / statistics.totalTrips) / statistics.target * 100).toFixed(1) : false;

    const subtitle = (loading ? 'Fetching...' : `${trips.length} till refetch`) + (percentReviewed ? ` (${percentReviewed}%)` : ``);
    const actions = [];
    if (trip) {
      actions.push({ to: `/trip/${ trip.id }`, icon: 'route', target: '_blank' });
    }

    return (
      <div className="fm-report-parking">
        <NavigationBar title="Parking Review" loading={loading} rightActions={actions} subtitle={subtitle} showBack={true} />
        <div className="fm-report-parking-content">
          <div className="fm-report-parking-review">
            <div className="fm-report-parking-review-image" id="fm-report-image" style={{ backgroundImage: trip ? `url('${ trip.parkingPhotoUrl }')` : undefined }}>
              { loading && <Loading /> }
              { (!loading && trips.length === 0) && <p className="fm-report-parking-empty">No rides to review.</p> }
            </div>
            <div className="fm-report-parking-review-options" id="fm-report-parking-review-options">
              <div className="fm-report-parking-review-option" onClick={() => this.handleReview('indeterminable')}>
                <div className="fm-report-parking-review-option-icon">
                  <Icon icon="question-circle" />
                </div>
                <div className="fm-report-parking-review-option-text">
                  <p className="fm-report-parking-review-option-title">Indeterminable</p>
                  <p className="fm-report-parking-review-option-description">Unable to definitely classify parking.</p>
                </div>
              </div>
              { types.map(this.renderType) }
            </div>
          </div>
        </div>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default ReportParkingReview;
