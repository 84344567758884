import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

const models = [
  { value: 'SEGWAY_S90L', label: 'Segway S90L' },
  { value: 'SEGWAY_A200', label: 'Segway A200' },
  { value: 'SEGWAY_MAX_PLUS_X', label: 'Segway Max Plus X' },
  { value: 'SEGWAY_MAX_PLUS', label: 'Segway Max Plus' },
  { value: 'SEGWAY_MAX_PRO', label: 'Segway Max Pro' },
  { value: 'SEGWAY_MAX', label: 'Segway Max' },
  { value: 'SEGWAY_ES', label: 'Segway ES' },
];

class AddVehicle extends Component {
  constructor() {
    super();
    this.state = { vehicle: { model: 'SEGWAY_S90L', vehicleSn: '', qrCode: '', registration: '', hasWiredHelmetLock: false }, loading: false };

    this.change = this.change.bind(this);
    this.keyDown = this.keyDown.bind(this);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleError = this.handleError.bind(this);

    this.inputVehicleSn = React.createRef();
    this.inputQr = React.createRef();
    this.inputRegistration = React.createRef();
  }

  componentDidMount() {
    document.title = 'Add Vehicle | Flamingo Admin';
    this.inputVehicleSn.current.focus();
  }

  handleAdd() {
    this.setState({ loading: true, error: false });
    const vehicle = Object.assign({}, this.state.vehicle);

    if (vehicle.vehicleSn.length !== 14) {
      return this.setState({ loading: false, error: 'Invalid Vehicle Serial Number' });
    }

    if (vehicle.qrCode.length !== 6 || !/^\d+$/.test(vehicle.qrCode)) {
      return this.setState({ loading: false, error: 'Invalid QR code' });
    }

    if (vehicle.registration.length !== 4 || !/^\d+$/.test(vehicle.registration)) {
      return this.setState({ loading: false, error: 'Invalid registration' });
    }

    return api.post('/vehicle/add', vehicle)
      .then((res) => this.setState({ vehicle: { model: 'SEGWAY_A200', vehicleSn: '', qrCode: '', registration: '' }, loading: false, error: false }, () => alert('Vehicle added! Activation should take approximately 10 minutes.')))
      .then(() => this.inputVehicleSn.current.focus())
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  change(key, e) {
    const vehicle = this.state.vehicle;
    if (key === 'hasWiredHelmetLock') {
      vehicle[key] = e.target.value === true || e.target.value === 'true';
    } else {
      vehicle[key] = e.target.value;
    }
    this.setState({ vehicle });
  }

  keyDown(key, e) {
    if (e.key === 'Enter') {
      switch (key) {
        case 'vehicleSn':
          return this.inputQr.current.focus();
        case 'qrCode':
          return this.inputRegistration.current.focus();
        default:
          this.handleAdd();
      }
    }
  }

  render() {
    const { loading, vehicle, error } = this.state;
    const { model, vehicleSn, qrCode, registration, hasWiredHelmetLock } = vehicle;
    const beamAction = { to: { pathname: `/vehicle/add/beam` }, icon: 'rocket' };

    return (
      <div className="fm-add">
        <NavigationBar title="Add Vehicle" loading={loading} rightActions={[beamAction]} />
        <div className="fm-add-content">
          <div className="fm-add-input">
            <p className="fm-input-label">Vehicle Model</p>
            <select value={model} className="fm-input fm-input-select" onChange={(e) => this.change('model', e)}>
              { models.map((option, i) => <option value={option.value} key={i}>{option.label}</option>) }
            </select>
          </div>
          <div className="fm-add-input">
            <p className="fm-input-label">Vehicle Serial Number</p>
            <input ref={this.inputVehicleSn} value={vehicleSn} className="fm-input" onChange={(e) => this.change('vehicleSn', e)} onKeyDown={(e) => this.keyDown('vehicleSn', e)} />
          </div>
          <div className="fm-add-input">
            <p className="fm-input-label">QR Code</p>
            <input ref={this.inputQr} value={qrCode} className="fm-input" onChange={(e) => this.change('qrCode', e)} onKeyDown={(e) => this.keyDown('qrCode', e)} pattern="\d*" maxLength={6} />
          </div>
          <div className="fm-add-input">
            <p className="fm-input-label">Registration</p>
            <input ref={this.inputRegistration} value={registration} className="fm-input" onChange={(e) => this.change('registration', e)} onKeyDown={(e) => this.keyDown('registration', e)} pattern="\d*" maxLength={4} />
          </div>
          <div className="fm-add-input">
            <p className="fm-input-label">Wired Helmet Lock</p>
            <select value={hasWiredHelmetLock} className="fm-input fm-input-select" onChange={(e) => this.change('hasWiredHelmetLock', e)}>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          <button className="fm-task-new-button" onClick={this.handleAdd}>{ loading ? 'Loading...' : 'Add Vehicle' }</button>
        </div>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default AddVehicle;
